// react
import React, { useEffect } from 'react'

// global components
import Header from 'components/Header'
import Footer from 'components/Footer'
import Whatsapp from 'components/Whatsapp.jsx'

// local components
import Banner from './components/Banner.jsx'
import OurCandidates from './components/OurCandidates.jsx'
import About from './components/About'
import GovernmentPlan from './components/GovernmentPlan.jsx'

function Home() {
  useEffect(() => {
    document.title = 'Hidek 44 Novamente - São João do Ivaí'
    window.scrollTo(0, 0)
  })

  return (
    <main style={{}}>
      <Header />
      <Banner />
      <About />
      <GovernmentPlan />
      <OurCandidates />
      <Footer />
      <Whatsapp />
    </main>
  )
}

export default Home
