import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// Importação das imagens dos candidatos
import caio from 'assets/images/santinhos/caiosilva.png'
import carlito from 'assets/images/santinhos/carlitocarteiro.png'
import carlos from 'assets/images/santinhos/carlosfruta.png'
import carol from 'assets/images/santinhos/carolrodrigues.png'
import chapolim from 'assets/images/santinhos/chapolimoficina.png'
import cintia from 'assets/images/santinhos/cintiacazonato.png'
import claito from 'assets/images/santinhos/clailtonmussato.png'
import devanir from 'assets/images/santinhos/devanironibus.png'
import elias from 'assets/images/santinhos/eliasvalle.png'
import fatinha from 'assets/images/santinhos/fatinha.png'
import joao from 'assets/images/santinhos/joaoguitslaff.png'
import johnatan from 'assets/images/santinhos/jonathansantiago.png'
import josecarlos from 'assets/images/santinhos/josecarlos.png'
import jrloro from 'assets/images/santinhos/juniorloro.png'
import juliana from 'assets/images/santinhos/julianamartins.png'
import larte from 'assets/images/santinhos/lartecontrutor.png'
import leila from 'assets/images/santinhos/leilamatos.png'
import luana from 'assets/images/santinhos/luanagentine.png'
import luciano from 'assets/images/santinhos/lucianobeca.png'
import luiz from 'assets/images/santinhos/luizdarodoviaria.png'
import macarrao from 'assets/images/santinhos/macarrao.png'
import marcia from 'assets/images/santinhos/marciaposto.png'
import marcio from 'assets/images/santinhos/marciocarretairo.png'
import maria from 'assets/images/santinhos/mariamaia.png'
import neia from 'assets/images/santinhos/neiaknupp.png'
import paulinha from 'assets/images/santinhos/paulinhaborin.png'
import reginaldo from 'assets/images/santinhos/reginaldocabecao.png'
import roger from 'assets/images/santinhos/rogerpredreiro.png'
import silmara from 'assets/images/santinhos/silmaraazoni.png'
import silvana from 'assets/images/santinhos/silvanamendes.png'
import thiago from 'assets/images/santinhos/thiagohenrique.png'
import tiba from 'assets/images/santinhos/tibamonteiro.png'
import tonhao from 'assets/images/santinhos/tonhaobadola.png'
import zelomba from 'assets/images/santinhos/zelomba.png'

const OurCandidates = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: null,
    prevArrow: null,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          autoplaySpeed: 4000,
          dots: true,
          nextArrow: null,
          prevArrow: null,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplaySpeed: 3000,
          dots: false,
          nextArrow: null,
          prevArrow: null,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplaySpeed: 3000,
          dots: false,
          nextArrow: null,
          prevArrow: null,
        },
      },
    ],
  }

  // Lista dos candidatos em ordem alfabética
  const candidates = [
    { name: 'Caio', image: caio },
    { name: 'Carlito', image: carlito },
    { name: 'Carlos', image: carlos },
    { name: 'Carol', image: carol },
    { name: 'Chapolim', image: chapolim },
    { name: 'Cintia', image: cintia },
    { name: 'Claiton', image: claito },
    { name: 'Devanir', image: devanir },
    { name: 'Elias', image: elias },
    { name: 'Fatinha', image: fatinha },
    { name: 'João', image: joao },
    { name: 'Johnatan', image: johnatan },
    { name: 'José', image: josecarlos },
    { name: 'Jr Loro', image: jrloro },
    { name: 'Juliana', image: juliana },
    { name: 'Larte', image: larte },
    { name: 'Leila', image: leila },
    { name: 'Luana', image: luana },
    { name: 'Luciano', image: luciano },
    { name: 'Luiz', image: luiz },
    { name: 'Macarrão', image: macarrao },
    { name: 'Márcia', image: marcia },
    { name: 'Márcio', image: marcio },
    { name: 'Maria', image: maria },
    { name: 'Neia', image: neia },
    { name: 'Paulinha', image: paulinha },
    { name: 'Reginaldo', image: reginaldo },
    { name: 'Roger', image: roger },
    { name: 'Silmara', image: silmara },
    { name: 'Silvana', image: silvana },
    { name: 'Thiago', image: thiago },
    { name: 'Tiba', image: tiba },
    { name: 'Tonhão', image: tonhao },
    { name: 'Zelomba', image: zelomba },
  ]

  return (
    <section id='nossos-candidatos' className='flex w-full flex-col items-center justify-center'>
      <div className='my-8 w-11/12 text-center md:my-16 md:w-10/12'>
        <header className='mb-4 flex flex-col items-center justify-center gap-2'>
          <h2 className='text-2xl font-bold uppercase md:text-5xl'>Nossos Vereadores</h2>
          <p className='text-lg'>
            Conheça quem está pronto para ouvir e trabalhar por você. São rostos e histórias que refletem nossa
            comunidade e nosso futuro.
          </p>
        </header>

        <Slider {...settings}>
          {candidates.map((candidate) => (
            <div className='p-2' key={candidate.name}>
              <img
                src={candidate.image}
                alt={candidate.name}
                loading='lazy'
                className='rounded-xl'
                onError={() => console.error(`Erro ao carregar imagem: ${candidate.image}`)}
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}

export default OurCandidates
