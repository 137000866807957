import React from 'react'
import hidek from 'assets/images/hidek.png'
import olivia from 'assets/images/olivia.png'

function About() {
  return (
    <section id='quem-somos' className='flex w-full flex-col items-center justify-center gap-4'>
      <div className='my-8 grid w-11/12 grid-cols-1 items-center justify-center gap-8 md:my-16 md:w-10/12 md:grid-cols-2'>
        <div className='flex flex-col gap-4'>
          <img src={hidek} alt='Fabio Hidek' className='rounded-full bg-blue' />
        </div>
        <div className='flex flex-col gap-4'>
          <header className='flex flex-col gap-2'>
            <span className='text-2xl font-medium'>Candidato a Prefeito</span>
            <h2 className='text-2xl font-bold uppercase md:text-4xl'>Fábio Hidek</h2>
          </header>
          <p className='text-justify md:text-lg xl:text-xl'>
            Queridos amigos de São João do Ivaí, primeiramente, agradeço a todos pela confiança depositada em mim no ano
            de 2012, nos conduzindo à frente do Executivo Municipal. Batalhamos muito para fazer uma São João melhor,
            dedicando-nos ao máximo para buscar recursos nos governos federal e estadual.
          </p>
          <p className='text-justify md:text-lg xl:text-xl'>
            Muitas vezes me ausentei de casa, deixando minha família à espera, com o objetivo de buscar melhorias ou
            resolver problemas da comunidade. Mas todos os sacrifícios valeram a pena, pois, com muita fé e
            determinação, conseguimos cerca de R$ 26 milhões em obras e investimentos em um único mandato, algo que
            nenhum prefeito havia feito antes.
          </p>
          <p className='text-justify md:text-lg xl:text-xl'>
            Isso se deve ao esforço, dedicação e, acima de tudo, ao trabalho coletivo. A coesão do grupo alavancou
            nossos projetos, melhorou os bairros de nossa cidade e, assim, proporcionou uma melhor qualidade de vida aos
            são-joanenses. Quero muito que vocês, eleitores e eleitoras, analisem o antes e o agora do município para,
            então, decidir quem merece te representar pelos próximos 4 anos.
          </p>
          <p className='text-justify md:text-lg xl:text-xl'>
            Sei que, mesmo fazendo muito, ainda temos muito por fazer e melhorar alguns setores. Porém, agora podemos
            oferecer a nossa experiência, aliada à força de vontade da nossa juventude. Por isso, venho aqui prestar
            contas desses 4 anos e pedir um novo voto de confiança para exercermos um novo mandato, com mais garra e
            novos projetos, para fazermos de nossa cidade uma São João do Ivaí ainda melhor.
          </p>
        </div>
      </div>
      <div className='my-8 grid w-11/12 grid-cols-1 items-center justify-center gap-8 md:my-16 md:w-10/12 md:grid-cols-2'>
        <div className='flex md:hidden flex-col gap-4'>
          <img src={olivia} alt='Olívia Fróes' className='rounded-full bg-blue' />
        </div>
        <div className='flex flex-col gap-4'>
          <header className='flex flex-col gap-2'>
            <span className='text-2xl font-medium'>Candidato a Vice</span>
            <h2 className='text-2xl font-bold uppercase md:text-4xl'>Olívia Fróes</h2>
          </header>
          <p className='text-justify md:text-lg xl:text-xl'>
            Sou Olívia Fróes, natural de São João do Ivaí/PR. Com 34 anos, desempenho com competência e sabedoria os
            papéis de esposa, mãe, enfermeira, professora e vereadora. Sou uma destacada atleta de basquete e católica
            cristã, sempre participativa nas ações comunitárias da igreja.
          </p>
          <p className='text-justify md:text-lg xl:text-xl'>
            Fui eleita vereadora em 2020 com 534 votos, sendo a única representante feminina em um colegiado de 9
            vereadores. Enfrento desafios ao levantar bandeiras como a da mulher na política e combater o racismo
            institucional. Como procuradora da mulher na Câmara Municipal, defendo causas como a dos autistas, dos
            servidores públicos e a participação feminina na política.
          </p>
          <p className='text-justify md:text-lg xl:text-xl'>
            Meu trabalho e dedicação me levaram a ser convidada para me candidatar a vice-prefeita de São João do
            Ivaí, decisão celebrada por todos que me conhecem. Acredito na política como uma ferramenta de transformação
            e, apesar dos desafios, busco sempre valores, oportunidades iguais, justiça social e transparência.
          </p>
          <p className='text-justify md:text-lg xl:text-xl'>
            Desejo o melhor para todos em São João do Ivaí e que a cidade siga em frente novamente!
          </p>
        </div>
        <div className='hidden md:flex flex-col gap-4'>
          <img src={olivia} alt='Olívia Fróes' className='rounded-full bg-blue' />
        </div>
      </div>
    </section>
  )
}

export default About
