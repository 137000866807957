import React from 'react'
import uniaobrasil from 'assets/icons/uniaobrasil.png'
import instagram from 'assets/icons/instagram.svg'
import facebook from 'assets/icons/facebook.svg'
import email from 'assets/icons/email.svg'

function Footer() {
  return (
    <footer id='contato' className='flex min-h-[50vh] w-full flex-col bg-blue text-white'>
      <div className='w-full bg-yellow p-4'></div>

      <div className='flex flex-col justify-between items-center w-full'>
        <div className='mx-auto my-8 grid w-11/12 grid-cols-1 items-start gap-8 md:my-16 md:w-10/12 md:grid-cols-4 md:justify-center'>
          <a href='https://hidek44novamente.com.br' className='flex justify-center md:justify-start'>
            <img src={uniaobrasil} alt='União Brasil Logo' className='h-auto w-48' />
          </a>

          <div className='md:text-left'>
            <h3 className='mb-2 text-2xl font-bold text-white uppercase'>Links Importantes</h3>
            <ul className='flex flex-col gap-2'>
              <li>
                <a href='#inicio' className='text-blue-200 transition-all duration-500 hover:text-yellow'>
                  Início
                </a>
              </li>
              <li>
                <a href='#quem-somos' className='text-blue-200 transition-all duration-500 hover:text-yellow'>
                  Quem Somos
                </a>
              </li>
              <li>
                <a href='#plano-de-governo' className='text-blue-200 transition-all duration-500 hover:text-yellow'>
                  Plano de Governo
                </a>
              </li>
              <li>
                <a href='#nossos-candidatos' className='text-blue-200 transition-all duration-500 hover:text-yellow'>
                  Nossos Candidatos
                </a>
              </li>
            </ul>
          </div>

          <div className='md:text-left'>
            <h3 className='mb-2 text-2xl font-bold text-white uppercase'>Contato</h3>
            <ul className='flex flex-col gap-2'>
              <li>
                <a href='#' target='_blank' className='text-blue-200 transition-all duration-500 hover:text-yellow'>
                  São João do Ivaí - PR, 85570-000
                </a>
              </li>
              <li>
                <a href='mailto:hidek44novamente@gmail.com' className='text-blue-200 transition-all duration-500 hover:text-yellow'>
                  hidek44novamente@gmail.com
                </a>
              </li>
              <li>
                <a href='tel:44999454444' className='text-blue-200 transition-all duration-500 hover:text-yellow'>
                  (44) 9 9945-4444
                </a>
              </li>
            </ul>
          </div>

          <div className='md:text-left'>
            <h3 className='mb-4 text-xl font-bold text-white uppercase'>Redes Sociais</h3>
            <ul className='flex flex-col gap-2'>
              <li>
                <a
                  href='https://instagram.com/hidek44novamente/'
                  target='_blank'
                  className='text-blue-200 flex items-center gap-2 transition-all duration-500 hover:text-yellow'
                >
                  <img src={instagram} alt='Instagram' className='h-6 w-6' />
                  <span>@hidek44novamente</span>
                </a>
              </li>
              <li>
                <a
                  href='https://facebook.com/hidek44novamente/'
                  target='_blank'
                  className='text-blue-200 flex items-center gap-2 transition-all duration-500 hover:text-yellow'
                >
                  <img src={facebook} alt='Facebook' className='h-6 w-6' />
                  <span>@hidek44novamente</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className='w-full bg-white p-4 text-black'>
          <div className='text-blue-900 flex flex-col items-center justify-around gap-2 text-center text-xs md:flex-row'>
            <p className='flex-1'>ELEICAO 2024 FABIO HIDEK MIURA PREFEITO: CNPJ 56.462.304/0001-25.</p>
            <p className='flex-1'>&copy;ELEICAO 2024 FABIO HIDEK MIURA PREFEITO. Todos os direitos reservados.</p>
            <p className='flex-1'>
              <a href='https://dorac.com.br' target='_blank' className='text-blue-900 hover:underline'>
                Desenvolvido por Agência Dorac
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
