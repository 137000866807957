import React, { useEffect } from 'react'
import logo from 'assets/icons/logo.png'

function NotFound() {
  useEffect(() => {
    document.title = 'Hidek 44 Novamente - São João do Ivaí'
    window.scrollTo(0, 0)
  }, [])

  return (
    <main className='flex min-h-screen w-full items-center justify-center bg-blue'>
      <div className='flex w-11/12 flex-col items-center justify-center gap-6 text-center md:w-10/12'>
        <a href='https://hidek44novamente.com.br'>
          <picture className='flex w-full items-center justify-center'>
            <img src={logo} alt='União Brasil' className='w-full md:w-1/2' />
          </picture>
        </a>
        <div className='flex flex-col gap-2'>
          <h1 className='text-2xl font-bold uppercase text-white md:text-5xl'>Site em Manutenção</h1>
          <p className='text-md w-full text-white md:text-xl'>
            Estamos trabalhando para melhorar a sua <br /> experiência. Por favor, volte mais tarde.
          </p>
        </div>
      </div>
    </main>
  )
}

export default NotFound
