import React from 'react'
import whatsapp from 'assets/icons/whatsapp.svg'

function Whatsapp() {
  const message =
    'Olá, vim pelo site e gostaria de saber mais sobre suas propostas e ações. Como posso ajudar a construir um futuro melhor para nossa comunidade?'
  const phoneNumber = '554499454444'
  const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`

  return (
    <a href={whatsappLink} target='_blank' className='fixed bottom-6 right-4 z-30'>
      <picture>
        <img src={whatsapp} alt='WhatsApp' loading='lazy' className='h-20 w-20 hover:opacity-75' />
      </picture>
    </a>
  )
}

export default Whatsapp
