import React from 'react'
import bannersm from 'assets/images/banner-sm.png'
import bannermd from 'assets/images/banner-md.png'
import bannerlg from 'assets/images/banner-lg.png'
import bannerxl from 'assets/images/banner-xl.png'

function Banner() {
  return (
    <section id='inicio' className='flex w-full items-center justify-center'>
      <picture className='h-auto w-full'>
        <source media='(min-width: 1440px)' srcSet={bannerxl} />
        <source media='(min-width: 1024px)' srcSet={bannerlg} />
        <source media='(min-width: 768px)' srcSet={bannermd} />
        <img src={bannersm} alt='Banner' loading='lazy' className='max-h-svh min-h-svh w-full' />
      </picture>
    </section>
  )
}

export default Banner
