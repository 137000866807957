import React, { useState } from 'react';

const GovernmentPlan = () => {
  const [expandedCardIndex, setExpandedCardIndex] = useState(null);

  const handleCardClick = (index) => {
    setExpandedCardIndex(expandedCardIndex === index ? null : index);
  };

  const cards = [
    {
      title: 'Grandes Obras',
      icon: 'engineering',
      title: 'Grandes Obras',
      icon: 'engineering',
      topics: [
        'Centro de Equoterapia',
        'Criação da Creche do Vovô',
        'Ampliação do Parque Industrial',
        'Construção do condomínio dos idosos',
        'Construção da Sala Multissensorial 6D',
        'Construção do Lago da entrada da cidade',
        'Asfalto de qualidade em todos os Distritos',
        'Instalar Usinas fotovoltaicas do Município',
        'Criar Pequenas Cooperativas e Mini Abatedouro',
        'Criação de Transporte Urbano - Distritos à Sede',
        'Construção ou aquisição de Hospital Municipal',
        'Revitalização das entradas principais da cidade',
        'Construção de Casas Populares no município e distritos'
      ]
    },
    {
      title: 'Infraestrutura',
      icon: 'construction',
      topics: [
        'Construção do Parcão',
        'Melhoria do Fundi Vale',
        'Reforma do cemitério antigo',
        'Ampliação do Parque industrial',
        'Reforma no Ginásio de Esportes',
        'Ampliar o Parque de Exposições',
        'Construção do Hospital Municipal',
        'Pavimentação de 100% dos Distritos',
        'Melhorar a coleta seletiva do lixo',
        'Instalação de pequenas Cooperativas',
        'Revitalização do bosque do Jardim Ivaí',
        'Melhorias dos cemitérios dos distritos',
        'Revitalização do bosque do Jardim Ivaí',
        'Restaurar a quadras esportivas da cidade',
        'Construção da sede da associação DAR a MÃO',
        'Melhorar a conservação dos prédios públicos',
        'Instalação LED em 100% da cidade e distritos',
        'Revitalização e melhorias do campo olímpico',
        'Ampliação do centro de convivência do idoso',
        'Instalação de Usinas Fotovoltaicas Municipais',
        'Construção de ponto de ônibus nas saídas da cidade',
        'Melhorar a manutenção de todas as estradas rurais',
        'Pista de caminhada com iluminação até o parque industrial',
        'Construção de Mini abatedouro para atender pequenos produtores',
        'Construção de Casas populares (sede e distritos) como nos mandatos anteriores',
        'Melhorar a sinalização das ruas, com faixa de pedestres, quebra-molas, placas, entre outras',
        'Construção de uma capela, banheiros, instalação de bancos e plantio de árvores do novo cemitério',
        'Construção de pista de laço coberta no parque de exposição, juntamente com uma pista de Motocross nível nacional',
        'Criação do Canil e ampliação do Centro Cirúrgico dos Animais, diminuindo a médio prazo o aumento dos animais abandonados',
        'Reforma e manutenção das pequenas praças, assim como as que foram reformadas nos últimos 4 anos (Arthur, Av. Itaipu e Praça Carriola, Praça da Igreja, Praça da Bíblia, Duque de Caxias)',
        'Reforma e manutenção das pequenas praças, assim como as que foram reformadas nos últimos 4 anos (Arthur, Av. Itaipu e Praça Carriola, Praça da Igreja, Praça da Bíblia, Duque de Caxias)',
      ],
    },
    {
      title: 'Saúde',
      icon: 'health_and_safety',
      topics: [
        'Farmácia municipal itinerante',
        'Aumentar o fornecimento de medicações',
        'Ampliação de grupos psicoterapêuticos',
        'Instalação do aparelho de Raio X novo',
        'Espaço físico próprio para base do SAMU',
        'Construção/aquisição de UBS – Jardim Ivaí',
        'Realocação da secretaria municipal de saúde',
        'Criação do centro de atendimento psicológico',
        'Adesão de cadeira de rodas, órteses e próteses',
        'Melhorar os programas odontológicos já criados',
        'Melhoria e ampliação do atendimento odontológico',
        'Criação do CENTRO INTEGRADO SAUDE DA MULHER (CISAM)',
        'Aumentar a relação de medicação do município (REMUME)',
        'Atendimento de fisioterapia no domicílio e nos distritos',
        'Criação de um Centro de recuperação aos dependentes químicos',
        'Garantir atividade física para a terceira idade nos distritos',
        'Mudança de horários para os transportes da saúde intermunicipal',
        'Criação do programa de Olho no Futuro – campanha avaliação oftálmica',
        'Garantia de materiais, cursos, instrumentos voltados para a área da psicologia',
        'Implantação da Academia da Terceira Idade – Vila Rural de Ubaúna; Centro do Idoso',
        'Credenciamento para tratamento oncológico nos municípios de Arapongas e Apucarana',
        'Aquisição de veículos disponíveis para as UBS e centro de reabilitação (fisioterapia)',
        'Descentralização da UBS que atende o Conjunto Ney Braga, Residencial Caleffi, Jardim Candoti',
        'Criação do centro de especialidades (cardiologia, ortopedia, neurologia, vascular, ginecologia)',
        'Ampliar os atendimentos em Pediatria, garantindo à todas as crianças, o direito à Saúde Infantil',
        'Garantir o atendimento de exames das grávidas em São João do Ivaí e demanda de partos no município',
        'Garantir atendimento prioritário a idosos, gestantes, crianças de 0 a 6 anos e pacientes especiais',
        'Continuar a Confeccionar o programa de próteses bucais (Dentaduras) que se iniciaram na gestão Hidek 2013-2020',
        'Construção ou aquisição do Hospital Municipal, bem como a melhoria e aquisição de novos aparelhos hospitalares',
        'Cirurgias eletivas de pequena e media complexidade (vasectomia, laqueadura, herniorrafia, etc..) no hospital municipal',
        'Aquisição de mais Veículos Van/Micro-ônibus/Spin para transporte de pacientes, com destinos como Londrina, Arapongas, Apucarana, Ivaiporã, entre outros',
      ]
    },
    {
      title: 'Educação',
      icon: 'school',
      topics: [
        'Ampliação dos Parquinhos',
        'Garantia do piso salarial',
        'Estabelecer Apoio total a Apae',
        'Acabar com as salas Multisseriadas',
        'Valorização das escolas dos distritos',
        'Ampliação de grupos psicoterapêuticos',
        'Criação do reforço escolar estruturado',
        'Quadra Poliesportiva para a Vovó Barbara',
        'Realocação da secretaria municipal de saúde',
        'Garagem própria para os veículos da Educação',
        'Escolha para diretor escolar através de eleições',
        'Criar o Centro de Atendimento a Criança Especial',
        'Melhoria e ampliação do atendimento odontológico',
        'Criação de laboratório de informática nas escolas',
        'Criação do CENTRO INTEGRADO SAUDE DA MULHER (CISAM)',
        'Atendimento de fisioterapia no domicílio e nos distritos',
        'Garantir atividade física para a terceira idade nos distritos',
        'Mudança de horários para os transportes da saúde intermunicipal',
        'Buscar recursos federais e estaduais para construir novas escolas',
        'Implantação de câmeras de segurança em todas as escolas municipais',
        'Melhorar a capacidade de atendimento para as crianças do CEMEI Vovó Barbara',
        'Criar projeto de língua estrangeira (inglês e espanhol) para educação infantil',
        'Garantia de materiais, cursos, instrumentos voltados para a área da psicologia',
        'Implantação da Academia da Terceira Idade – Vila Rural de Ubaúna; Centro do Idoso',
        'Professor de apoio para as crianças com transtorno do espectro autista, conforme a legislação',
        'Atualização do Plano de Cargos e Carreiras para os professores e funcionários da rede municipal',
        'Criação da lei específica para o Processo Seletivo Simplificado (PSS) com a carga horária de 20h e 40h',
        'Manter e melhorar material escolar e uniforme que começaram a ser distribuídos no mandado do Hidek em 2017 a 2020',
        'Implementação do contraturno gradativo na educação infantil, incluindo dança, esporte, reforço escolar, língua e atividades tecnológicas',
        'Investir ainda mais na manutenção e compra de equipamentos pedagógicos e educacionais (salas tecnológicas, lousas digitais, computadores e etc)',
        'Criar Lei específica que favoreçam os professores, para que os mesmos, possam garantir o direito de suas licenças prêmios por critérios definidos em Lei',
        'Garanti um atendimento igualitário a todos os alunos, sem restrição, tendo como base um padrão de qualidade preestabelecido pela Secretaria Municipal de Educação',
        'Garantir um atendimento igualitário a todos os alunos, sem restrição, tendo como base um padrão de qualidade preestabelecido pela Secretaria Municipal de Educação',
      ],
    },
    {
      title: 'Indústria, Comércio e Turismo',
      icon: 'business',
      topics: [
        'Ampliação do Parque Industrial',
        'Instalação de Barracões Industriais',
        'Incentivos aos Micro Empreendedores',
        'Oferecimento de vários cursos profissionalizantes',
        'Fortalecimento do Comércio Local em todos os setores',
        'Construção de Barracões Industriais, gerando novos empregos',
        'Incentivos as empresas que dão oportunidade aos jovens do 1º emprego',
        'Desvinculação da Secretaria da Indústria e Comércio de outras Secretarias',
        'Criação de uma instituição que forneça capacitação aos Jovens do 1º emprego',
        'Oferecer incentivos fiscais para que as empresas locais regularizem suas situações cadastrais',
      ],
    },
    {
      title: 'Agricultura e Pecuária',
      icon: 'agriculture',
      topics: [
        'Apoio a Piscicultura',
        'Reativar o Viveiro Municipal',
        'Implantação da horta comunitária',
        'Instalação de pequenas Cooperativas',
        'Fomentar os turismos rurais do município',
        'Pontos para coletas de lixos na área rural',
        'Aquisição de Barracas para a Feira do Produtor',
        'Pavimentar a Estrada da Prainha e as Vilas Rurais',
        'Cursos profissionalizantes do SENAR para os agricultores/as',
        'Construção de Mini abatedouro para atender pequenos produtores',
        'Reativar o Programa de Inseminação Artificial visando melhoria dos Gados',
        'Incentivo ao Agricultores, ex. calcário, refrigeradores para a produção de leite',
        'Construção de Casas populares rurais, assim como foram feitas no mandato do Hidek',
        'Descentralização da Patrulha Mecanizada para atendimento no meio rural e pequenos produtores',
        'Melhorar infraestrutura operacional para as agentes do Programa PSF no meio rural, inclusive de suporte de veículos próprios',
        'Reativar Programa Municipal de Readequação viária, para adequação e melhorias das estradas vicinais e carreadores das propriedades',
        'Desenvolver ações de suporte e apoio ao Conselho Municipal de Desenvolvimento Rural, criando mecanismos para o fundo de desenvolvimento rural',
        'Reativar e reformular as Associações de Produtores Rurais, para ofertar cursos de Capacitação com apoio técnico nas diversas áreas, agricultura familiar, agronegócio e outros',
      ],
    },
    {
      title: 'Meio Ambiente',
      icon: 'eco',
      topics: [
        'Criar o bosque municipal',
        'Implantação de viveiro de mudas',
        'Revitalização da Lagoa prateada',
        'Implantar projeto de arborização',
        'Reestruturar a coleta de lixo municipal',
        'Criar Projetos que protejam as minas e nascentes',
        'Consolidar programas de reciclagem e compostagem do lixo',
        'Projetar Novo aterro sanitário, para atender as normas vigentes',
        'Transformar o Parque Ecológico em unidade geradora de ICMS Ecológico',
        'Melhorar a limpeza de todas as ruas e bairros da cidade e dos distritos',
        'Projeto de Restruturação de árvores urbanas com a distribuição de mudas',
        'Garantir a manutenção e recuperação das Áreas de Proteção Permanente (APP)',
        'Promover caminhadas ecológicas com intuito educacional, para que o cidadão conheça as nossas belezas naturais',
      ],
    },
    {
      title: 'Segurança',
      icon: 'security',
      topics: [
        'Implantar os bombeiros comunitários',
        'Retomada dos policiais no posto rodoviário',
        'Instalação de câmeras de segurança nos ambientes escolares',
        'Melhorar e Capacitar a Defesa Civil Municipal criada em 2014',
        'Melhorar a Iluminação de várias ruas escuras em todos os bairros',
        'Implantar a criação da Guarda Municipal estabelecida pela Lei de 2016',
        'Aumentar ainda mais o contingente de Policiais Militares e Civis no município',
        'Melhorar e ampliar para os distritos o Projeto de Câmeras de Segurança instalado no mandado Hidek 2017-2020',
        'Atuar com parcerias entre a corporações civis e militares, incluindo ainda a população como colaboradora da segurança municipal',
        'Realização de ronda escolar pela Guarda Municipal, para garantir a segurança das crianças e adolescentes e a tranquilidade dos pais',
        'Atuar junto ao Conselho Tutelar no monitoramento de crianças dependentes químicos, e envolvidos com usuários, a fim de resgatá-los, dando condições para reintegração escolar, profissional e ainda acompanhamento de saúde',
      ],
    },
    {
      title: 'Assistência Social',
      icon: 'people',
      topics: [
        'Implantação da Creche do Vovô',
        'Sede própria para o conselho tutelar',
        'Ampliar atividades para a terceira idade',
        'Melhorar ações do programa Bolsa Família e cadastro único',
        'Cursos profissionalizantes para a comunidade urbana e distrital',
        'Manter e buscar novas parcerias dos programas do Governo Estadual e Federal',
        'Melhorar os atendimentos oferecidos pelo Serviço de Convivência (antigo CEMIC)',
        'Criar capacitações aos adolescentes, preparando ele para o mercado de trabalho',
        'Expandir os cursos de qualificação profissional e geração de renda para os Distritos e Sede',
        'Desenvolver ações de combate ao uso de drogas, lícitas e ilícitas em parceria com outros departamentos',
        'Expandir o Programa Tarifa Social da Sanepar, aumentando o número de famílias de baixa renda incluídas no programa',
        'Melhora no Programa Frente de Trabalho, aumentando o valor de remuneração e garantindo também a ajuda do vale alimentação',
        'Manter e melhorar os serviços de proteção social básica (CRAS) e serviços de proteção especial de média complexidade (CREAS)',
        'Criação do Programa Ajuda ao Vovô, onde junto com a saúde vamos garantir a vida com qualidade aos nossos munícipes da melhor idade',
        'Garantir a execução de Programas Governamentais, que tem por objetivo levar serviços essenciais à população tais como, documentação, cadastramento em programas sociais, saúde, justiça, entre outros'
      ],
    },
    {
      title: 'Esporte',
      icon: 'sports',
      topics: [
        'Promover maratona',
        'Reforma do Estádio de futebol',
        'Adesão de materiais esportivos',
        'Criação do conselho desportivo',
        'Construção de pistas para caminhadas',
        'Criação da Secretaria Própria do Esporte',
        'Criação do Calendário Esportivo Municipal',
        'Fornecimento de uniformes para atletas do município',
        'Construção de mais campos suíços e quadras esportivas',
        'Oferecer Ginástica e atividades esportivas nos distritos',
        'Voltar a participar dos jogos estaduais, Abertos, Javis e outros',
        'Participar do Programa da Paraná esporte, o Esporte que queremos',
        'Criação de Campeonatos Municipais de todas as modalidades e categorias',
        'Incentivar as atividades motociclísticas (gincanas, motocross, trilhas)',
        'Aquisição de Vários veículos para o Esporte, inclusive para os distritos',
        'Promover de ações de práticas esportivas para comemoração dia nacional do idoso',
        'Criação de lei específica para custear inscrição de atletas que irão representar o município',
        'Oferecer programas de lazer à terceira idade, como viagens, passeios e atividades recreativas',
        'Incentivar torneio de sinuca, bocha, malha, truco, xadrez, prova de laço, cavalgada, paradão de som',
        'Criar eventos entre distritos, para que haja integração dos mesmos, além de mais opções de atividades e lazer',
        'Voltar a ter Escolinha de todas as modalidades esportivas (basquete, vôlei, handebol, futsal, tênis de mesa, xadrez, karatê, atletismo, capoeira entre outros)',
      ],
    },
    {
      title: 'Cultura',
      icon: 'theaters',
      topics: [
        'Reativar o Teatro Municipal',
        'Valorização da Folia de Reis',
        'Feira de artesanatos e comidas',
        'Reativar o Clube XX de dezembro',
        'Incentivar as exposições nas escolas',
        'Apoio as Festas Festivas e Populares',
        'Elaborar calendário de atrações culturais',
        'Manter a festa da cidade na respectiva data',
        'Baile da terceira idade na sede e distritos',
        'Criar uma biblioteca virtual para a comunidade',
        'Melhoria e aumento dos Instrumentos da Fanfarra',
        'Promover produções artísticas (danças, música, teatro)',
        'Priorizar os shows com os artistas locais e o comércio local',
        'Promover as festividades natalinas no Município com o Projeto do “Natal de Luz”',
      ],
    },
    {
      title: 'Santa Luzia da Alvorada',
      icon: '',
      topics: [
        'Melhoria no sistema de água',
        'Instalação da Guarda Municipal',
        'Iluminação do campo de futebol suíço',
        'Criação de Praça na entrada do distrito',
        'Pavimentação Asfáltica de todas as ruas',
        'Cursos de Capacitação para os moradores',
        'Construção da Capela Mortuária no centro',
        'Melhora na Iluminação Pública de todas as ruas',
        'Melhorar a Limpeza e coleta de lixo no Distrito',
        'Melhora no serviço de limpeza e recolhimento do lixo',
        'Oferecer Apoio técnico aos pequenos Produtores Rurais',
        'Instalação de Câmeras de segurança como foi feito na cidade',
        'Fomento de emprego através de mini cooperativas e mini abatedouro',
        'Incentivar o treinamento desportivo de crianças e adolescentes do distrito',
        'Atendimento médico 2x por semana com medicamentos sendo distribuídos diretamente no Posto de Saúde',
        'Buscar parceria junto aos governos para a construção de mais casas populares como as que foram feitas'
      ],
    },
    {
      title: 'Luar',
      icon: '',
      topics: [
        'Atendimento Odontológico',
        'Melhorias no Trevo de acesso',
        'Construção da Capela Mortuária',
        'Construção de Campo Futebol Suíço',
        'Pavimentação Asfáltica de todas as ruas',
        'Melhorar a iluminação de todo o Distrito',
        'Reforma do barracão da Vila Rural do Luar',
        'Melhora no serviço de limpeza e recolhimento do lixo',
        'Reforma do Cemitério e melhoria nos serviços funerais',
        'Reforma da Praça da Igreja com a melhoria da Iluminação',
        'Instalação de Câmeras de segurança como foi feito na cidade',
        'Fomento de emprego através de mini cooperativas e mini abatedouro',
        'Implantar a escolinha desportivo de criança e adolescentes do distrito',
        'Reforma do vestiário e do Campo de Futebol com a implantação de iluminação',
        'Instalação e Efetividade da Guarda Municipal, oportunizando a sensação de segurança aos moradores',
        'Atendimento médico 2x por semana com medicamentos sendo distribuídos diretamente no Posto de Saúde',
        'Buscar parceria junto aos governos para a construção de novas casas populares, assim como já foram feitas',
      ],
    },
    {
      title: 'Ubaúna',
      icon: '',
      topics: [
        "Iluminação do Campo de Futebol",
        "Pavimentação Asfáltica de todas as ruas",
        "Melhorar a iluminação de todo o Distrito",
        "Construção do barracão da Vila Rural de Ubaúna",
        "Melhora no serviço de limpeza e recolhimento do lixo",
        "Oferecer Apoio técnico aos pequenos Produtores Rurais",
        "Melhorar a coleta de lixo no distrito e comunidades rurais",
        "Instalação de Câmeras de segurança como foi feito na cidade",
        "Fomento de emprego através de mini cooperativas e mini abatedouro",
        "Ampliação do Barracão Industrial e incentivos aos novos empreendedores",
        "Buscar parceria junto aos governos para a construção de casas populares",
        "Implantar o treinamento desportivo de crianças e adolescentes do distrito",
        "Instalação e Efetividade da Guarda Municipal, oportunizando a sensação de segurança aos moradores",
        "Atendimento médico 2x por semana com medicamentos sendo distribuídos diretamente no Posto de Saúde",
      ],
    },
  ];

  return (
    <section id="plano-de-governo" className="flex w-full flex-col justify-center items-center gap-4 bg-gray-50 py-8">
      <div className="flex flex-col items-center justify-center gap-4 w-11/12 md:w-10/12">
        <header className="mb-4 flex flex-col items-center justify-center text-center gap-2">
          <h2 className="text-2xl font-bold uppercase md:text-5xl">Plano de Governo</h2>
          <p className="text-lg">
            Confira nossas propostas para levar São João do Ivaí para frente novamente.
          </p>
        </header>

        <div className="grid grid-cols-1 gap-6">
          {cards.map((card, index) => (
            <div
              key={index}
              className={`flex flex-col gap-4 rounded-2xl h-auto bg-blue text-white p-6 cursor-pointer`}
              onClick={() => handleCardClick(index)}
            >
              <header className="flex items-center">
                <span className="material-icons text-4xl mr-1" aria-hidden="true">
                  {card.icon}
                </span>
                <h3 className="text-xl font-semibold md:text-3xl uppercase">{card.title}</h3>
              </header>

              <div className='flex flex-col gap-2'>
                {/* Conteúdo fixo do card */}
                <ul className="grid grid-cols-1 gap-2 list-disc ps-4 md:grid-cols-2">
                  {card.topics.slice(0, 8).map((topic, topicIndex) => (
                    <li key={topicIndex} className="font-medium">
                      <p className="w-full md:w-11/12 text-justify">
                        {topic}
                      </p>
                    </li>
                  ))}
                </ul>

                {/* Conteúdo expandido */}
                {expandedCardIndex === index && (
                  <ul className="grid grid-cols-1 gap-2 list-disc ps-4 md:grid-cols-2">
                    {card.topics.slice(8).map((topic, topicIndex) => (
                      <li key={topicIndex} className="font-medium">
                        <p className="w-full md:w-11/12 text-justify">
                          {topic}
                        </p>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default GovernmentPlan;